import axios from 'axios';

// const baseURL = "https://api.aakshauhini.in/delizeal-develop/";
const baseURL = "https://api.vphospitalityservices.com/delizeal-develop/";
const axiosInstance = axios.create({
    baseURL,
    withCredentials: false,

    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      }
  });

  
  axiosInstance.interceptors.request.use((config) => {

    const token = JSON.parse(localStorage.getItem('token'));
    if (
      token != null
    ) {
       
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  });
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const { response } = error;

      if (error.response.status === 401) {
        window.location = "/signin";
        localStorage.clear();
      }
  
      return Promise.reject(error.response);
    }
  );
  
  export default axiosInstance;