import { Redirect, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import Home from "./pages/Home";
import Login from "./pages/Signin";
import Register from "./pages/Signup";
import VerifyOTP from "./pages/VerifyOtp";
import Dashboard from "./pages/Dashboard";
import Support from "./pages/Support";
import About from "./pages/About";
import MenuList from "./pages/Orders/MenuList";
import OrderHistory from "./pages/Orders/OrderHistory";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/global.scss";

import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndCondition from "./pages/TermsAndCondition";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Menu from "./pages/Menu";
import OrderReviews from "./pages/Orders/OrderReviews";
import OrderDetails from "./pages/Orders/OrderDetails";
import ProtectedRoute from "./ProtectedRoute";
import { isDesktop, isMobile } from "react-device-detect";
import {
  statsChartOutline,
  cartSharp,
  createSharp,
  settingsSharp,
  repeatOutline,
  appsOutline,
  layersOutline,
  settingsOutline,
  checkmarkCircleOutline,
  closeCircleOutline,
  colorWandOutline,
  readerOutline,
  starOutline,
  trendingUpOutline,
  arrowBackCircleOutline,
  restaurantOutline,
} from "ionicons/icons";
import Settings from "./pages/Settings";
import Cart from "./pages/Orders/Cart";
import CounterHeadDashboard from "./pages/CounterHead/CounterHeadDashboard";
import OrderRequests from "./pages/CounterHead/OrderRequestReceived";
import Reports from "./pages/CounterHead/Reports";
import Payments from "./pages/Orders/Payments";
import Reviews from "./pages/CounterHead/Reviews";
import OrderRequestReceived from "./pages/CounterHead/OrderRequestReceived";
import OrderRequestInprogress from "./pages/CounterHead/OrderRequestInprogress";
import OrderRequestDelivered from "./pages/CounterHead/OrderRequestDelivered";
import EditMenu from "./pages/CounterHead/EditMenu";
import OrderRequestRejected from "./pages/CounterHead/OrderRequestRejected";
import OrderRequestReady from "./pages/CounterHead/OrderRequestReady";
import PaymentProcessing from "./pages/PaymentProcessing";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import Vendors from "./pages/Admin/Vendors";
import Menus from "./pages/Admin/Menus";
import Sites from "./pages/Admin/Sites";
import AdminReports from "./pages/Admin/AdminReports";
import Occupancy from "./pages/CounterHead/Occupancy";
import OrderRequestCancelled from "./pages/CounterHead/OrderRequestCancelled";
import OrderStatus from "./pages/CounterHead/OrderStatus";

setupIonicReact();

const App: React.FC = () => {
  const [auth, setAuth] = useState("0");
  const [userDetails, setuserDetails] = useState({
    firstname: "",
    lastname: "",
    role: "",
  });

  const [hideMobileTabs, sethideMobileTabs] = useState(true);

  const authRoutes = [
    "/signin",
    "/signup",
    "/verifyOTP",
    "/forgotPassword",
    "/resetPassword",
    "/privacy-policy",
    "/terms",
  ];
  useEffect(() => {
    console.log(localStorage.getItem("userDetails"));

    if (localStorage.getItem("userDetails") != null) {
      console.log("if");
      let userDetails = JSON.parse(localStorage.getItem("userDetails")!);
      setuserDetails(userDetails);
      setAuth("1");

      console.log(userDetails.role);
      console.log(window.location.href.includes("/signin"));

      if (window.location.href.includes("/signin")) {
        if (userDetails.role == "User") {
          window.location.href = "/dashboard";
        } else if (userDetails.role == "CounterHead") {
          window.location.href = "/counter/dashboard";
        } else if (userDetails.role == "CounterLiveStatus") {
          window.location.href = "/live/orderStatus";
        } else if (userDetails.role == "Admin") {
          window.location.href = "/admin/dashboard";
        }
      }

      // else {
      //   localStorage.clear();
      //   window.location.href = "/signin";
      // }
    } else {
      console.log("else", window.location.pathname);

      if (authRoutes.find((e) => e == window.location.pathname)) {
        // console.log("match......");
      } else {
        window.location.href = "/signin";
      }
      setAuth("0");
    }
  }, []);

  function renderTabs() {
    return (
      <IonTabs>
        <IonRouterOutlet>{renderRoutes("mobile")}</IonRouterOutlet>

        {userDetails.role == "User" && (
          <IonTabBar slot="bottom" id="dynamicTabs">
            <IonTabButton tab="dashboard" href="/dashboard">
              <IonIcon icon={appsOutline} />
            </IonTabButton>
            <IonTabButton tab="menu" href="/menu">
              <IonIcon icon={layersOutline} />
            </IonTabButton>
            <IonTabButton tab="orderHistory" href="/orderHistory">
              <IonIcon icon={repeatOutline} />
            </IonTabButton>
            {/* <IonTabButton tab="orderDetails" href="/orderSummary">
              <IonIcon icon={repeatOutline} />
            </IonTabButton>
            {/* <IonTabButton tab="settings" href="/settings">
              <IonIcon icon={settingsOutline} />
            </IonTabButton> */}
            {/* <IonTabButton tab="logout" href="/signin">
              <IonIcon icon={arrowBackCircleOutline} />
            </IonTabButton> */}
          </IonTabBar>
        )}
        {userDetails.role == "CounterHead" && (
          <IonTabBar slot="bottom" id="dynamicTabs">
            <IonTabButton tab="dashboard" href="/counter/dashboard">
              <IonIcon icon={appsOutline} />
            </IonTabButton>
            <IonTabButton tab="editMenu" href="/counter/editMenu">
              <IonIcon icon={readerOutline} />
            </IonTabButton>
            <IonTabButton tab="ordersReceived" href="/counter/orders-received">
              <IonIcon icon={layersOutline} />
            </IonTabButton>
            <IonTabButton
              tab="ordersInProgress"
              href="/counter/orders-inprogress"
            >
              <IonIcon icon={colorWandOutline} />
            </IonTabButton>

            <IonTabButton tab="ordersReady" href="/counter/orders-ready">
              <IonIcon icon={restaurantOutline} />
            </IonTabButton>
            <IonTabButton tab="ordersRejected" href="/counter/orders-rejected">
              <IonIcon icon={closeCircleOutline} />
            </IonTabButton>
            <IonTabButton
              tab="ordersDelivered"
              href="/counter/orders-delivered"
            >
              <IonIcon icon={checkmarkCircleOutline} />
            </IonTabButton>
            <IonTabButton tab="reports" href="/counter/reports">
              <IonIcon icon={trendingUpOutline} />
            </IonTabButton>
            <IonTabButton tab="reviews" href="/counter/reviews">
              <IonIcon icon={starOutline} />
            </IonTabButton>
            <IonTabButton tab="occupancy" href="/counter/occupancy">
              <IonIcon icon={starOutline} />
            </IonTabButton>
            <IonTabButton tab="cancelled" href="/counter/cancelled">
              <IonIcon icon={closeCircleOutline} />
            </IonTabButton>

            {/* <IonTabButton tab="settings" href="/settings">
              <IonIcon icon={settingsOutline} />
            </IonTabButton> */}
            {/* <IonTabButton tab="logout" href="/signin">
              <IonIcon icon={arrowBackCircleOutline} />
            </IonTabButton> */}
          </IonTabBar>
        )}

        {userDetails.role == "CounterLiveStatus" && (
          <IonTabBar slot="bottom" id="dynamicTabs">
            <IonTabButton tab="orderStatus" href="/live/orderStatus">
              <IonIcon icon={starOutline} />
            </IonTabButton>
          </IonTabBar>
        )}

        {userDetails.role == "Admin" && (
          <IonTabBar slot="bottom" id="dynamicTabs">
            <IonTabButton tab="dashboard" href="/admin/dashboard">
              <IonIcon icon={appsOutline} />
            </IonTabButton>
          </IonTabBar>
        )}
      </IonTabs>
    );
  }

  function renderRoutes(type: string) {
    return (
      <IonRouterOutlet id={type == "web" ? "main-content" : ""}>
        {/* <ProtectedRoute path="/home" component={Home} exact /> */}
        <ProtectedRoute path="/support" component={Support} exact />
        <ProtectedRoute path="/dashboard" component={Dashboard} exact />
        <ProtectedRoute path="/about" component={About} exact />
        <ProtectedRoute path="/menu" component={MenuList} exact />
        <ProtectedRoute path="/orderHistory" component={OrderHistory} exact />
        <ProtectedRoute path="/settings" component={Settings} exact />
        <ProtectedRoute path="/cart" component={Cart} exact />
        <ProtectedRoute path="/orderReviews" component={OrderReviews} exact />
        <ProtectedRoute path="/payments" component={Payments} exact />
        <ProtectedRoute path="/orderSummary" component={OrderDetails} exact />
        <ProtectedRoute
          path="/processing"
          component={PaymentProcessing}
          exact
        />
        {/* This is for counter head role */}
        <ProtectedRoute
          path="/counter/dashboard"
          component={CounterHeadDashboard}
          exact
        />
        <ProtectedRoute
          path="/counter/orders-received"
          component={OrderRequestReceived}
          exact
        />
        <ProtectedRoute
          path="/counter/orders-inprogress"
          component={OrderRequestInprogress}
          exact
        />
        <ProtectedRoute
          path="/counter/orders-ready"
          component={OrderRequestReady}
          exact
        />
        <ProtectedRoute
          path="/counter/orders-rejected"
          component={OrderRequestRejected}
          exact
        />
        <ProtectedRoute
          path="/counter/orders-delivered"
          component={OrderRequestDelivered}
          exact
        />
        <ProtectedRoute path="/counter/reports" component={Reports} exact />
        <ProtectedRoute path="/counter/reviews" component={Reviews} exact />
        <ProtectedRoute path="/counter/occupancy" component={Occupancy} exact />
        <ProtectedRoute
          path="/counter/cancelled"
          component={OrderRequestCancelled}
          exact
        />
        <ProtectedRoute path="/counter/editMenu" component={EditMenu} exact />
        <ProtectedRoute
          path="/live/orderStatus"
          component={OrderStatus}
          exact
        />

        {/* This is for admin role */}
        <ProtectedRoute
          path="/admin/dashboard"
          component={AdminDashboard}
          exact
        />
        <ProtectedRoute path="/admin/menus" component={Menus} exact />
        <ProtectedRoute path="/admin/vendors" component={Vendors} exact />
        <ProtectedRoute path="/admin/sites" component={Sites} exact />
        <ProtectedRoute path="/admin/reports" component={AdminReports} exact />
      </IonRouterOutlet>
    );
  }

  function preventRandomRoutes() {
    console.log("go to 404....");
    return "/signin";
    // return auth == "1"
    //   ? userDetails.role == "CounterHead"
    //     ? "/counter/dashboard"
    //     : "/menu"
    //   : "/signin";
  }

  return (
    // pass the store into the provider
    <Provider store={store}>
      <IonApp>
        {isDesktop && (
          <>
            <IonReactRouter>
              {auth == "1" ? (
                //when user is authenticated

                <>
                  {userDetails.role != "CounterLiveStatus" ? (
                    <IonSplitPane when="sm" contentId="main-content">
                      <Menu isAuthenticated={false} />
                      {renderRoutes("web")}
                    </IonSplitPane>
                  ) : (
                    <>{renderRoutes("web")}</>
                  )}
                </>
              ) : (
                //when user is not authenticated
                <IonReactRouter>
                  <Route exact path="/">
                    <Redirect to="/signin" />
                  </Route>
                  <Route path="/signin" component={Login} exact />
                  <Route path="/signup" component={Register} exact />
                  <Route path="/verifyOTP" component={VerifyOTP} exact />
                  <Route
                    path="/forgotPassword"
                    component={ForgotPassword}
                    exact
                  />
                  <Route path="/resetPassword" component={ResetPassword} />
                  <Route
                    path="/privacy-policy"
                    component={PrivacyPolicy}
                    exact
                  />
                  <Route path="/terms" component={TermsAndCondition} exact />
                </IonReactRouter>
              )}
              {/* <Redirect from="/" to="/signin" /> */}
              {/* <Redirect path="/counter/signin" to="/signin" exact /> */}

              {/* <Redirect from="*" to="/terms" exact /> */}
            </IonReactRouter>
          </>
        )}

        {isMobile && (
          <>
            <IonReactRouter>
              {auth == "1" ? (
                //when user is authenticated
                renderTabs()
              ) : (
                <IonReactRouter>
                  <Route exact path="/">
                    <Redirect to="/signin" />
                  </Route>
                  <Route path="/signin" component={Login} exact />
                  <Route path="/signup" component={Register} exact />
                  <Route path="/verifyOTP" component={VerifyOTP} exact />
                  <Route
                    path="/forgotPassword"
                    component={ForgotPassword}
                    exact
                  />
                  <Route path="/resetPassword" component={ResetPassword} />
                  <Route
                    path="/privacy-policy"
                    component={PrivacyPolicy}
                    exact
                  />
                  <Route path="/terms" component={TermsAndCondition} exact />
                </IonReactRouter>
              )}
              {/* <Redirect path="/counter/signin" to="/signin" exact /> */}

              {/* <Redirect from="*" to="/terms" exact /> */}
            </IonReactRouter>
          </>
        )}
      </IonApp>
    </Provider>
  );
};

export default App;
