import React, { useEffect, useState } from "react";
import {
  IonApp,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonFooter,
  IonSplitPane,
  IonMenu,
  IonButton,
  IonButtons,
  IonList,
  IonListHeader,
  IonMenuToggle,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import { RouteComponentProps, withRouter, useLocation } from "react-router";
import { connect } from "react-redux";
import "./Menu.css";

import {
  appsOutline,
  layersOutline,
  repeatOutline,
  settingsOutline,
  arrowBackCircleOutline,
  barChartOutline,
  checkboxOutline,
  colorWandOutline,
  closeCircleOutline,
  readerOutline,
  checkmarkCircleOutline,
  trendingUpOutline,
  starOutline,
  restaurantOutline,
} from "ionicons/icons";
interface Pages {
  title: string;
  path: string;
  icon: string;
  routerDirection?: string;
}
import { home, menu } from "ionicons/icons";
const routes = {
  appPages: [
    { title: "Dashboard", path: "/dashboard", icon: appsOutline },
    { title: "Menu List", path: "/menu", icon: layersOutline },
    { title: "Order History", path: "/orderHistory", icon: repeatOutline },
    // { title: 'Settings', path: '/settings', icon: settingsOutline }
    // { title: 'Logout', path: '/signin', icon: arrowBackCircleOutline }
  ],
  counterHeadPages: [
    { title: "Dashboard", path: "/counter/dashboard", icon: appsOutline },
    { title: "Edit Menu", path: "/counter/editMenu", icon: readerOutline },
    {
      title: "Orders Recevied",
      path: "/counter/orders-received",
      icon: layersOutline,
    },
    {
      title: "Orders In Progress",
      path: "/counter/orders-inprogress",
      icon: colorWandOutline,
    },
    {
      title: "Orders Ready",
      path: "/counter/orders-ready",
      icon: restaurantOutline,
    },
    {
      title: "Orders Delivered",
      path: "/counter/orders-delivered",
      icon: checkmarkCircleOutline,
    },
    {
      title: "Orders Rejected",
      path: "/counter/orders-rejected",
      icon: closeCircleOutline,
    },
    {
      title: "Order Cancelled",
      path: "/counter/cancelled",
      icon: closeCircleOutline,
    },
    { title: "Reports", path: "/counter/reports", icon: trendingUpOutline },
    { title: "Reviews", path: "/counter/reviews", icon: starOutline },
    { title: "Occupancy", path: "/counter/occupancy", icon: starOutline },
    // { title: "Settings", path: "/settings", icon: settingsOutline },
  ],

  adminPages: [
    { title: "Dashboard", path: "/admin/dashboard", icon: appsOutline },
    { title: "Menus", path: "/admin/menus", icon: readerOutline },
    { title: "Vendors", path: "/admin/vendors", icon: readerOutline },
    { title: "Sites", path: "/admin/sites", icon: readerOutline },
    { title: "Reports", path: "/admin/reports", icon: readerOutline },
  ],

  // counterLiveStatusPages: [
  //   { title: "Order Status", path: "/live/orderStatus", icon: starOutline },
  // ],
};
function renderlistItems(list: Pages[]) {
  return list
    .filter((route) => !!route.path)
    .map((p) => (
      <IonMenuToggle key={p.title} auto-hide="false">
        <IonItem
          detail={false}
          routerLink={p.path}
          routerDirection="none"
          className={
            location.pathname.startsWith(p.path) ? "selected" : undefined
          }
        >
          <IonIcon slot="start" icon={p.icon} />
          <IonLabel>{p.title}</IonLabel>
        </IonItem>
      </IonMenuToggle>
    ));
}

interface MenuProps extends RouteComponentProps {
  isAuthenticated: boolean;
  menuEnabled: boolean;
}

const Menu: React.FC<MenuProps> = ({
  isAuthenticated = true,
  history,
  menuEnabled,
}) => {
  const [userDetails, setuserDetails] = useState({
    firstname: "",
    lastname: "",
    role: "",
  });

  useEffect(() => {
    if (localStorage.getItem("userDetails") != null) {
      let userDetails = JSON.parse(localStorage.getItem("userDetails")!);
      setuserDetails(userDetails);
    }
  }, []);

  return (
    <>
      <IonMenu
        type="overlay"
        contentId="main-content"
        className="app-left-navigation"
      >
        <IonHeader>
          <IonToolbar>
            <picture className="app-header-logo">
              <img src="assets/img/logo.png" alt="Ionic logo" />
            </picture>
          </IonToolbar>
        </IonHeader>
        <IonContent forceOverscroll={false}>
          <IonList lines="none">
            {userDetails.role == "User" && (
              <>{renderlistItems(routes.appPages)}</>
            )}
            {userDetails.role == "CounterHead" && (
              <>{renderlistItems(routes.counterHeadPages)}</>
            )}
            {userDetails.role == "Admin" && (
              <>{renderlistItems(routes.adminPages)}</>
            )}
          </IonList>
        </IonContent>
      </IonMenu>
    </>
  );
};

function mapStateToProps(state: any) {
  return {
    menuEnabled: state.data.menuEnabled,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Menu));
