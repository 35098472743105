export const COUNTERHEAD_ACTION_TYPES = {
    // Define Action types
    GET_INCOMING_ORDER_REQUEST: "GET_INCOMING_ORDER_REQUEST",
    GET_INCOMING_ORDER_REQUEST_SUCCESS: "GET_INCOMING_ORDER_REQUEST_SUCCESS",
    GET_INCOMING_ORDER_REQUEST_ERROR: "GET_INCOMING_ORDER_REQUEST_ERROR",


    ACCEPT_ORDER_REQUEST:"ACCEPT_ORDER_REQUEST",
    ACCEPT_ORDER_REQUEST_SUCCESS: "ACCEPT_ORDER_REQUEST_SUCCESS",
    ACCEPT_ORDER_REQUEST_ERROR: "ACCEPT_ORDER_REQUEST_ERROR",


    REJECT_ORDER_REQUEST:"REJECT_ORDER_REQUEST",
    REJECT_ORDER_REQUEST_SUCCESS: "REJECT_ORDER_REQUEST_SUCCESS",
    REJECT_ORDER_REQUEST_ERROR: "REJECT_ORDER_REQUEST_ERROR",

    
    READY_ORDER_REQUEST:"READY_ORDER_REQUEST",
    READY_ORDER_REQUEST_SUCCESS: "READY_ORDER_REQUEST_SUCCESS",
    READY_ORDER_REQUEST_ERROR: "READY_ORDER_REQUEST_ERROR",

    DELIVER_ORDER_REQUEST:"DELIVER_ORDER_REQUEST",
    DELIVER_ORDER_REQUEST_SUCCESS: "DELIVER_ORDER_REQUEST_SUCCESS",
    DELIVER_ORDER_REQUEST_ERROR: "DELIVER_ORDER_REQUEST_ERROR",

    GET_REPORTS_REQUEST:"GET_REPORTS_REQUEST",
    GET_REPORTS_REQUEST_SUCCESS:"GET_REPORTS_REQUEST_SUCCESS",
    GET_REPORTS_REQUEST_ERROR:"GET_REPORTS_REQUEST_ERROR",


    GET_REVIEWS_REQUEST:"GET_REVIEWS_REQUEST",
    GET_REVIEWS_REQUEST_SUCCESS:"GET_REVIEWS_REQUEST_SUCCESS",
    GET_REVIEWS_REQUEST_ERROR:"GET_REVIEWS_REQUEST_ERROR",

    GET_MENU_WISE_REPORTS_REQUEST:"GET_MENU_WISE_REPORTS_REQUEST",
    GET_MENU_WISE_REPORTS_REQUEST_SUCCESS:"GET_MENU_WISE_REPORTS_REQUEST_SUCCESS",
    GET_MENU_WISE_REPORTS_REQUEST_ERROR:"GET_MENU_WISE_REPORTS_REQUEST_ERROR",

    
    GET_DASHBOARD_STATS_REQUEST:"GET_DASHBOARD_STATS_REQUEST",
    GET_DASHBOARD_STATS_REQUEST_SUCCESS:"GET_DASHBOARD_STATS_REQUEST_SUCCESS",
    GET_DASHBOARD_STATS_REQUEST_ERROR:"GET_DASHBOARD_STATS_REQUEST_ERROR",


    
    GET_SEATING_COUNT_REQUEST:"GET_SEATING_COUNT_REQUEST",
    GET_SEATING_COUNT_REQUEST_SUCCESS:"GET_SEATING_COUNT_REQUEST_SUCCESS",
    GET_SEATING_COUNT_REQUEST_ERROR:"GET_SEATING_COUNT_REQUEST_ERROR",


    UPDATE_TIFFIN_USER_COUNT_REQUEST:"UPDATE_TIFFIN_USER_COUNT_REQUEST",
    UPDATE_TIFFIN_USER_COUNT_REQUEST_SUCCESS:"UPDATE_TIFFIN_USER_COUNT_REQUEST_SUCCESS",
    UPDATE_TIFFIN_USER_COUNT_REQUEST_ERROR:"UPDATE_TIFFIN_USER_COUNT_REQUEST_ERROR",


    
    GET_LIVE_ORDER_STATUS_REQUEST:"GET_LIVE_ORDER_STATUS_REQUEST",
    GET_LIVE_ORDER_STATUS_REQUEST_SUCCESS:"GET_LIVE_ORDER_STATUS_REQUEST_SUCCESS",
    GET_LIVE_ORDER_STATUS_REQUEST_ERROR:"GET_LIVE_ORDER_STATUS_REQUEST_ERROR",
};


export const getIncomingOrdersRequest = (status) => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_INCOMING_ORDER_REQUEST,
    payload: status,
});

export const getIncomingOrdersRequestSuccess = (orderData) => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_INCOMING_ORDER_REQUEST_SUCCESS,
    payload: orderData,
});


export const getIncomingOrdersRequestError = (orderData) => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_INCOMING_ORDER_REQUEST_ERROR,
    payload: orderData,
});


export const acceptOrderRequest = (acceptedData) => ({
    type: COUNTERHEAD_ACTION_TYPES.ACCEPT_ORDER_REQUEST,
    payload: acceptedData
});

export const acceptOrderRequestSuccess = () => ({
    type: COUNTERHEAD_ACTION_TYPES.ACCEPT_ORDER_REQUEST_SUCCESS
});


export const acceptOrderRequestError = () => ({
    type: COUNTERHEAD_ACTION_TYPES.ACCEPT_ORDER_REQUEST_ERROR
});



export const rejectOrderRequest = (selectedOrderId) => ({
    type: COUNTERHEAD_ACTION_TYPES.REJECT_ORDER_REQUEST,
    payload: selectedOrderId
});

export const rejectOrderSuccess = () => ({
    type: COUNTERHEAD_ACTION_TYPES.REJECT_ORDER_REQUEST_SUCCESS
});


export const rejectOrderError = () => ({
    type: COUNTERHEAD_ACTION_TYPES.REJECT_ORDER_REQUEST_ERROR
});


export const readyOrderRequest = (selectedOrderId) => ({
    type: COUNTERHEAD_ACTION_TYPES.READY_ORDER_REQUEST,
    payload: selectedOrderId
});

export const readyOrderSuccess = () => ({
    type: COUNTERHEAD_ACTION_TYPES.READY_ORDER_REQUEST_SUCCESS
});


export const readyOrderError = () => ({
    type: COUNTERHEAD_ACTION_TYPES.READY_ORDER_REQUEST_ERROR
});





export const deliverOrderRequest = (selectedOrderId) => ({
    type: COUNTERHEAD_ACTION_TYPES.DELIVER_ORDER_REQUEST,
    payload: selectedOrderId
});

export const deliverOrderSuccess = () => ({
    type: COUNTERHEAD_ACTION_TYPES.DELIVER_ORDER_REQUEST_SUCCESS
});


export const deliverOrderError = () => ({
    type: COUNTERHEAD_ACTION_TYPES.DELIVER_ORDER_REQUEST_ERROR
});


export const getReviewsRequest = () => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_REVIEWS_REQUEST
});

export const getReviewsSuccess = (reviewData) => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_REVIEWS_REQUEST_SUCCESS,
    payload: reviewData
});


export const getReviewsError = () => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_REVIEWS_REQUEST_ERROR
});



export const getReportsRequest = (dateFilter) => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_REPORTS_REQUEST,
    payload: dateFilter
});

export const getReportsSuccess = (reportData) => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_REPORTS_REQUEST_SUCCESS,
    payload: reportData
});


export const getReportsError = () => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_REPORTS_REQUEST_ERROR
});




export const getMenuWiseReportsRequest = (dateFilter) => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_MENU_WISE_REPORTS_REQUEST,
    payload: dateFilter
});

export const getMenuWiseReportsRequestSuccess = (menuWiseReportData) => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_MENU_WISE_REPORTS_REQUEST_SUCCESS,
    payload: menuWiseReportData
});


export const getMenuWiseReportsRequestError = () => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_MENU_WISE_REPORTS_REQUEST_ERROR
});


export const getDashboardStatsRequest = () => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_DASHBOARD_STATS_REQUEST
});

export const getDashboardStatsRequestSuccess = (dashboardStats) => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_DASHBOARD_STATS_REQUEST_SUCCESS,
    payload: dashboardStats
});


export const getDashboardStatsRequestError = () => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_DASHBOARD_STATS_REQUEST_ERROR
});


export const getSeatingCountRequest = () => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_SEATING_COUNT_REQUEST
});

export const getSeatingCountRequestSuccess = (reviewData) => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_SEATING_COUNT_REQUEST_SUCCESS,
    payload: reviewData
});


export const getSeatingCountRequestError = () => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_SEATING_COUNT_REQUEST_ERROR
});


export const updateTiffinUserCountRequest = (data) => ({
    type: COUNTERHEAD_ACTION_TYPES.UPDATE_TIFFIN_USER_COUNT_REQUEST,
    payload: data
});

export const updateTiffinUserCountRequestSuccess = () => ({
    type: COUNTERHEAD_ACTION_TYPES.UPDATE_TIFFIN_USER_COUNT_REQUEST_SUCCESS
});


export const updateTiffinUserCountRequestError = () => ({
    type: COUNTERHEAD_ACTION_TYPES.UPDATE_TIFFIN_USER_COUNT_REQUEST_ERROR
});

export const getLiveOrderStatusRequest = () => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_LIVE_ORDER_STATUS_REQUEST,

});

export const getLiveOrderStatusRequestSuccess = (data) => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_LIVE_ORDER_STATUS_REQUEST_SUCCESS,
    payload: data
});


export const getLiveOrderStatusRequestError = () => ({
    type: COUNTERHEAD_ACTION_TYPES.GET_LIVE_ORDER_STATUS_REQUEST_ERROR
});
