import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonBadge,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import {
  trashOutline,
  checkmarkCircleOutline,
  calendar,
  informationCircle,
  map,
  personCircle,
} from "ionicons/icons";
import Rater from "react-rater";
import { COUNTERHEAD_ACTION_TYPES } from "../../redux/user/counterhead/actions";

interface OwnProps {}

interface DispatchProps {}

interface ReviewsDeliveredProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  getReviews: Function;
  isgetReviewsSuccess: boolean;
  isgetReviewsError: boolean;
  reviewData: any;
}
const ReviewsDelivered: React.FC<ReviewsDeliveredProps> = ({
  setMenuAction,
  getReviews,
  isgetReviewsSuccess,
  isgetReviewsError,
  reviewData,
}) => {
  useEffect(() => {
    setMenuAction(true);
    getReviews();
  }, []);

  useEffect(() => {
    console.log(isgetReviewsSuccess);
    console.log(reviewData);
  }, [isgetReviewsSuccess]);

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop &&
                    <> */}

        <div className="listing-block">
          <IonLabel className="pagename">
            <h2>Orders Reviews</h2>
          </IonLabel>

          <IonCard className="table-layout">
            <IonCardContent>
              <IonRow>
                <IonCol size="6" size-md="6">
                  Filter:
                  <select className="input-field">
                    <option>select</option>
                    <option>Breakfast</option>
                    <option>Lunch</option>
                    <option>Snacks</option>
                  </select>
                </IonCol>

                <IonCol size="6" size-md="6">
                  <input
                    type="text"
                    placeholder="Search by name, id, context"
                    className="input-field input-field-full float-right"
                  />
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol size="12" size-md="12">
                  <div className="table-wrapper">
                    <table>
                      <tr>
                        <th>Order ID</th>
                        <th>User Name</th>
                        <th>User ID</th>
                        <th>Rating</th>
                        <th>Reviews</th>
                      </tr>

                      {reviewData.length > 0 &&
                        reviewData.map((review: any, index: Number) => {
                          return (
                            <tr>
                              <td>{review.orderId}</td>
                              <td>{review.userName}</td>
                              <td>{review.userId}</td>
                              <td>
                                <Rater
                                  total={5}
                                  rating={review.rating}
                                  interactive={false}
                                ></Rater>
                              </td>
                              <td>{review.review}</td>
                            </tr>
                          );
                        })}
                    </table>
                  </div>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        </div>

        {/* </>
                } */}

        {/* {isMobile &&
                    <>

                        <div className="listing-block">
                            <IonLabel className="pagename">
                                <h2>ReviewsDelivered</h2>
                            </IonLabel>

                            <IonCard className="display-card cart-view review-view mob-card-view">
                                <IonCardContent>
                                    <IonItem lines='none'>

                                    </IonItem>

                                </IonCardContent>
                            </IonCard>

                            <div className="mob-card-view-action">

                            </div>
                        </div>

                    </>
                } */}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isgetReviewsSuccess: state.counterheadData.isgetReviewsSuccess,
  isgetReviewsError: state.counterheadData.isgetReviewsError,
  reviewData: state.counterheadData.reviewData,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getReviews: () =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_REVIEWS_REQUEST,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsDelivered);
