import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import { trashOutline, checkmarkCircleOutline } from "ionicons/icons";
import { ACTION_TYPES, setCartCount } from "../../redux/user/orders/actions";
import { COUNTERHEAD_ACTION_TYPES } from "../../redux/user/counterhead/actions";

interface OwnProps {}

interface DispatchProps {}

interface CounterHeadDashboardProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  getMenuListAction: Function;
  menuData: any;
  getDashboardStatsAction: Function;
  isgetDashboardStatsSuccess: boolean;
  isgetDashboardStatsError: boolean;
  dashboardStats: any;
}
const CounterHeadDashboard: React.FC<CounterHeadDashboardProps> = ({
  setMenuAction,
  getMenuListAction,
  menuData,
  getDashboardStatsAction,
  dashboardStats,
  isgetDashboardStatsSuccess,
  isgetDashboardStatsError,
}) => {
  useEffect(() => {
    setMenuAction(true);
    getMenuListAction();
    getDashboardStatsAction();
  }, []);

  useEffect(() => {
    if (isgetDashboardStatsSuccess) {
      console.log(dashboardStats);
    }
  }, [isgetDashboardStatsSuccess]);

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop && */}
        <>
          <div className="listing-block">
            <IonLabel className="pagename">
              <h2>
                Dashboard <span className="role-badge">Counter Head</span>
              </h2>
            </IonLabel>

            <IonCard className="card-layout">
              <IonCardContent>
                <IonRow>
                  <IonCol size="12" size-md="12">
                    <h4>Today's data</h4>
                  </IonCol>

                  {isgetDashboardStatsSuccess && (
                    <IonCol size="12" size-md="12">
                      <div className="card-view">
                        <h5>Order received</h5>
                        <a href="/counter/orders-received">See all</a>
                        <span>{dashboardStats.ordersReceived}</span>
                      </div>

                      <div className="card-view">
                        <h5>Order delivered</h5>
                        <a href="/counter/orders-delivered">See all</a>
                        <span>{dashboardStats.ordersDelivered}</span>
                      </div>

                      <div className="card-view">
                        <h5>Reports</h5>
                        <a href="/counter/reports">See all</a>
                        <span>₹ {dashboardStats.totalSales}</span>
                      </div>

                      <div className="card-view">
                        <h5>Reviews received</h5>
                        <a href="/counter/reviews">See all</a>
                        <span>{dashboardStats.reviewsReceived}</span>
                      </div>

                      <div className="card-view">
                        <h5>Orders rejected</h5>
                        <a href="/counter/orders-rejected">See all</a>
                        <span>{dashboardStats.ordersRejected}</span>
                      </div>
                    </IonCol>
                  )}
                </IonRow>
              </IonCardContent>
            </IonCard>

            <IonCard className="table-layout">
              <IonCardContent>
                {menuData.length > 0 && (
                  <IonRow>
                    <IonCol size="12" size-md="12">
                      <h4>Today's packed meal</h4>
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <div className="table-wrapper">
                        <table>
                          <tr>
                            <th>Menu ID</th>
                            <th>Menu name</th>
                            <th>Price</th>
                          </tr>

                          {menuData.map((menu: any) => {
                            return (
                              <>
                                {menu.isIncludedInPackage == 1 && (
                                  <tr>
                                    <td>{menu.menuId}</td>
                                    <td>{menu.menuName}</td>
                                    <td>{menu.price}</td>
                                  </tr>
                                )}
                              </>
                            );
                          })}
                        </table>
                      </div>
                    </IonCol>
                  </IonRow>
                )}

                {menuData.length == 0 && <div>Menu data Is Empty...</div>}
              </IonCardContent>
            </IonCard>
          </div>
        </>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  menuData: state.orders.menuData,
  isgetDashboardStatsSuccess: state.counterheadData.isgetDashboardStatsSuccess,
  isgetDashboardStatsError: state.counterheadData.isgetDashboardStatsError,
  dashboardStats: state.counterheadData.dashboardStats,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getMenuListAction: () =>
      dispatch({
        type: ACTION_TYPES.GET_MENU_REQUEST,
      }),
    getDashboardStatsAction: () =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_DASHBOARD_STATS_REQUEST,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CounterHeadDashboard);
