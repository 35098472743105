import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import { trashOutline, checkmarkCircleOutline } from "ionicons/icons";

interface OwnProps {}

interface DispatchProps {}

interface VendorsProps extends OwnProps, DispatchProps {}
const Vendors: React.FC<VendorsProps> = ({}) => {
  useEffect(() => {}, []);

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop && */}
        <>
          <div className="listing-block">
            <IonLabel className="pagename">
              <h2>Vendors</h2>
            </IonLabel>

            <IonCard className="card-layout">
              <IonCardContent></IonCardContent>
            </IonCard>
          </div>
        </>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({});

function mapDispatchToProps(dispatch: any) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Vendors);
