export const ACTION_TYPES = {
    // Define Action types
    GET_ORDERS_REQUEST: "GET_ORDERS_REQUEST",
    GET_ORDERS_REQUEST_SUCCESS: "GET_ORDERS_REQUEST_SUCCESS",
    GET_ORDERS_REQUEST_ERROR: "GET_ORDERS_REQUEST_ERROR",


    GET_MENU_REQUEST: "GET_MENU_REQUEST",
    GET_MENU_REQUEST_SUCCESS: "GET_MENU_REQUEST_SUCCESS",
    GET_MENU_REQUEST_ERROR: "GET_MENU_REQUEST_ERROR",


    SET_ORDER_REQUEST: "SET_ORDER_REQUEST",
    SET_ORDER_REQUEST_SUCCESS: "SET_ORDER_REQUEST_SUCCESS",
    SET_ORDER_REQUEST_ERROR: "SET_ORDER_REQUEST_ERROR",
    

    GET_ORDER_HISTORY_REQUEST: "GET_ORDER_HISTORY_REQUEST",
    GET_ORDER_HISTORY_REQUEST_SUCCESS: "GET_ORDER_HISTORY_REQUEST_SUCCESS",
    GET_ORDER_HISTORY_REQUEST_ERROR: "GET_ORDER_HISTORY_REQUEST_ERROR",

    SET_CART_COUNT:"SET_CART_COUNT",

    ADD_TO_CART_REQUEST:"ADD_TO_CART_REQUEST",
    ADD_TO_CART_REQUEST_SUCCESS:"ADD_TO_CART_REQUEST_SUCCESS",
    ADD_TO_CART_REQUEST_ERROR:"ADD_TO_CART_REQUEST_ERROR",


    UPDATE_CART_REQUEST:"UPDATE_CART_REQUEST",
    UPDATE_CART_REQUEST_SUCCESS:"UPDATE_CART_REQUEST_SUCCESS",
    UPDATE_CART_REQUEST_ERROR:"UPDATE_CART_REQUEST_ERROR",

    DELETE_CART_REQUEST:"DELETE_CART_REQUEST",
    DELETE_CART_REQUEST_SUCCESS:"DELETE_CART_REQUEST_SUCCESS",
    DELETE_CART_REQUEST_ERROR:"DELETE_CART_REQUEST_ERROR",

    GET_CART_REQUEST:"GET_CART_REQUEST",
    GET_CART_REQUEST_SUCCESS:"GET_CART_REQUEST_SUCCESS",
    GET_CART_REQUEST_ERROR:"GET_CART_REQUEST_ERROR",

    ADD_REVIEWS_REQUEST:"ADD_REVIEWS_REQUEST",
    ADD_REVIEWS_REQUEST_SUCCESS:"ADD_REVIEWS_REQUEST_SUCCESS",
    ADD_REVIEWS_REQUEST_ERROR:"ADD_REVIEWS_REQUEST_ERROR",

    UPDATE_SUB_MENU_REQUEST:"UPDATE_SUB_MENU_REQUEST",
    UPDATE_SUB_MENU_REQUEST_SUCCESS:"UPDATE_SUB_MENU_REQUEST_SUCCESS",
    UPDATE_SUB_MENU_REQUEST_ERROR:"UPDATE_SUB_MENU_REQUEST_ERROR",


    UPDATE_MENU_REQUEST:"UPDATE_MENU_REQUEST",
    UPDATE_MENU_REQUEST_SUCCESS:"UPDATE_MENU_REQUEST_SUCCESS",
    UPDATE_MENU_REQUEST_ERROR:"UPDATE_MENU_REQUEST",

    

    RESET_UPDATES_REQUEST:"RESET_UPDATES_REQUEST",
    RESET_REQUEST:"RESET_REQUEST",


    
    PAY_ORDER_ONLINE_REQUEST: "PAY_ORDER_ONLINE_REQUEST",
    PAY_ORDER_ONLINE_REQUEST_SUCCESS: "PAY_ORDER_ONLINE_REQUEST_SUCCESS",
    PAY_ORDER_ONLINE_REQUEST_ERROR: "PAY_ORDER_ONLINE_REQUEST_ERROR",

    REPAY_CASH_REQUEST:"REPAY_CASH_REQUEST", 
    REPAY_CASH_REQUEST_SUCCESS:"REPAY_CASH_REQUEST_SUCCESS",
    REPAY_CASH_REQUEST_ERROR:"REPAY_CASH_REQUEST_ERROR",


    REPAY_ONLINE_PAYTM_REQUEST:"REPAY_ONLINE_PAYTM_REQUEST", 
    REPAY_ONLINE_PAYTM_REQUEST_SUCCESS:"REPAY_ONLINE_PAYTM_REQUEST_SUCCESS",
    REPAY_ONLINE_PAYTM_REQUEST_ERROR:"REPAY_ONLINE_PAYTM_REQUEST_ERROR",

    PAY_ORDER_ONLINE_SODEXO_REQUEST:"PAY_ORDER_ONLINE_SODEXO_REQUEST",
    PAY_ORDER_ONLINE_SODEXO_REQUEST_SUCCESS:"PAY_ORDER_ONLINE_SODEXO_REQUEST_SUCCESS",
    PAY_ORDER_ONLINE_SODEXO_REQUEST_:"PAY_ORDER_ONLINE_SODEXO_REQUEST",

    ORDER_CANCEL_REQUEST:"ORDER_CANCEL_REQUEST",
    ORDER_CANCEL_REQUEST_SUCCESS:"ORDER_CANCEL_REQUEST_SUCCESS",
    ORDER_CANCEL_REQUEST_ERROR:"ORDER_CANCEL_REQUEST_ERROR",

    
};

/*Get Orders Action*/
export const getOrdersRequest = () => ({
    type: ACTION_TYPES.GET_ORDERS_REQUEST
});

export const getOrdersSuccess = (orderData) => ({
    type: ACTION_TYPES.GET_ORDERS_REQUEST_SUCCESS,
    payload:orderData.responses
});


export const getOrdersError = () => ({
    type: ACTION_TYPES.GET_ORDERS_REQUEST_ERROR
});


/*Set Orders Action*/
export const setOrdersRequest = () => ({
    type: ACTION_TYPES.SET_ORDER_REQUEST
});

export const setOrdersSuccess = (orderedData) => ({
    type: ACTION_TYPES.SET_ORDER_REQUEST_SUCCESS,
    payload:orderedData
});


export const setOrdersError = () => ({
    type: ACTION_TYPES.SET_ORDER_REQUEST_ERROR
});


/*Get Orders History Action*/
export const getOrderHistoryRequest = (filterData) => ({
    type: ACTION_TYPES.GET_ORDER_HISTORY_REQUEST,
    payload: filterData
});

export const getOrderHistorySuccess = (orderHistoryData) => ({
    type: ACTION_TYPES.GET_ORDER_HISTORY_REQUEST_SUCCESS,
    payload:orderHistoryData
});


export const getOrderHistoryError = () => ({
    type: ACTION_TYPES.GET_ORDER_HISTORY_REQUEST_ERROR
});


/*Get Menu Action*/
export const getMenuRequest = () => ({
    type: ACTION_TYPES.GET_MENU_REQUEST
});

export const getMenuSuccess = (menuData) => ({
    type: ACTION_TYPES.GET_MENU_REQUEST_SUCCESS,
    payload:menuData
});


export const getMenuError = () => ({
    type: ACTION_TYPES.GET_MENU_REQUEST_ERROR
});

export const setCartCount = (count) => ({
    type: ACTION_TYPES.SET_CART_COUNT,
    payload:count
});


export const addtoCartRequest = () => ({
    type: ACTION_TYPES.ADD_TO_CART_REQUEST
    // payload:cartItem
});

export const addtoCartSuccess = () => ({
    type: ACTION_TYPES.ADD_TO_CART_REQUEST_SUCCESS
    // payload:count
});

export const addtoCartError = () => ({
    type: ACTION_TYPES.ADD_TO_CART_REQUEST_ERROR
    // payload:count
});


export const updateCartRequest = (updatedcartItem) => ({
    type: ACTION_TYPES.UPDATE_CART_REQUEST,
    payload:updatedcartItem
});

export const updateCartSuccess = () => ({
    type: ACTION_TYPES.UPDATE_CART_REQUEST_SUCCESS
    // payload:count
});

export const updateCartError = () => ({
    type: ACTION_TYPES.UPDATE_CART_REQUEST_ERROR
    // payload:count
});


export const deleteCartRequest = (updatedcartItem) => ({
    type: ACTION_TYPES.DELETE_CART_REQUEST,
    payload:updatedcartItem
});

export const deleteCartSuccess = () => ({
    type: ACTION_TYPES.DELETE_CART_REQUEST_SUCCESS
    // payload:count
});

export const deleteCartError = () => ({
    type: ACTION_TYPES.DELETE_CART_REQUEST_ERROR
    // payload:count
});



export const getCartRequest = (cartItem) => ({
    type: ACTION_TYPES.GET_CART_REQUEST,
    payload:cartItem
});

export const getCartSuccess = (cartItem) => ({
    type: ACTION_TYPES.GET_CART_REQUEST_SUCCESS,
    payload:cartItem
});

export const getCartError = () => ({
    type: ACTION_TYPES.GET_CART_REQUEST_ERROR
    // payload:count
});




export const addReviewsRequest = (reviews) => ({
    type: ACTION_TYPES.ADD_REVIEWS_REQUEST,
    payload:reviews
});

export const addReviewsSuccess = () => ({
    type: ACTION_TYPES.ADD_REVIEWS_REQUEST_SUCCESS
});

export const addReviewsError = () => ({
    type: ACTION_TYPES.ADD_REVIEWS_REQUEST_ERROR
});


export const updateSubMenuRequest = (updatedSubMenu) => ({
    type: ACTION_TYPES.UPDATE_SUB_MENU_REQUEST,
    payload:updatedSubMenu
});

export const updateSubMenuSuccess = (updatedSubMenu) => ({
    type: ACTION_TYPES.UPDATE_SUB_MENU_REQUEST_SUCCESS,
    payload:updatedSubMenu
});

export const updateSubMenuError = () => ({
    type: ACTION_TYPES.UPDATE_SUB_MENU_REQUEST_ERROR
});




export const updateMenuRequest = (updatedMenu) => ({
    type: ACTION_TYPES.UPDATE_MENU_REQUEST,
    payload:updatedMenu
});

export const updateMenuSuccess = (updatedMenu) => ({
    type: ACTION_TYPES.UPDATE_MENU_REQUEST_SUCCESS,
    payload:updatedMenu
});

export const updateMenuError = () => ({
    type: ACTION_TYPES.UPDATE_MENU_REQUEST_ERROR
});

export const reset = () => ({ 
    type: DATA_ACTION_TYPES.RESET_REQUEST
}); 


export const resetUpdate = () => ({ 
    type: DATA_ACTION_TYPES.RESET_UPDATES_REQUEST
}); 


export const payOrderOnlineRequest = (orderDetails) => ({
    type: ACTION_TYPES.PAY_ORDER_ONLINE_REQUEST,
    payload:orderDetails
});

export const payOrderOnlineSuccess = () => ({
    type: ACTION_TYPES.PAY_ORDER_ONLINE_REQUEST_SUCCESS
});

export const payOrderOnlineError = () => ({
    type: ACTION_TYPES.PAY_ORDER_ONLINE_REQUEST_ERROR
});


export const rePayCashRequest = (orderDetails) => ({
    type: ACTION_TYPES.REPAY_CASH_REQUEST,
    payload:orderDetails
});

export const rePayCashRequestSuccess = () => ({
    type: ACTION_TYPES.REPAY_CASH_REQUEST_SUCCESS
});

export const rePayCashRequestError = () => ({
    type: ACTION_TYPES.REPAY_CASH_REQUEST_ERROR
});


export const orderCancelRequest = (orderID) => ({
    type: ACTION_TYPES.ORDER_CANCEL_REQUEST,
    payload:orderID
});

export const orderCancelRequestSuccess = () => ({
    type: ACTION_TYPES.ORDER_CANCEL_REQUEST_SUCCESS
});

export const orderCancelRequestError = () => ({
    type: ACTION_TYPES.ORDER_CANCEL_REQUEST_ERROR
});
 

 






