import React, { useEffect, useState } from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonInput,
  IonText,
  useIonToast,
} from "@ionic/react";
import "./Login.scss";
import { RouteComponentProps } from "react-router";
import { Route, useLocation } from "react-router-dom";

import axios from "axios";
import CopyRight from "../components/CopyRight";
import { ACTION_TYPES } from "../redux/auth/actions";
import { connect } from "react-redux";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface VerifyOTPProps extends OwnProps, DispatchProps {
  isOtpVerifiedSuccess: false;
  isOtpVerifiedError: false;
  verifyOTPAction: Function;
}

const VerifyOTP: React.FC<VerifyOTPProps> = ({
  history,
  isOtpVerifiedSuccess,
  isOtpVerifiedError,
  verifyOTPAction,
}) => {
  const search = useLocation().search;
  const reset = new URLSearchParams(search).get("reset");
  const [messageToast] = useIonToast();
  const [data, setData] = useState({ otp: "", mobileNo: "" });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [disableResend, setDisableResend] = useState(true);

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };

  useEffect(() => {
    if (isOtpVerifiedSuccess) {
      let isRegister = JSON.parse(localStorage.getItem("isRegister")!)
        ? JSON.parse(localStorage.getItem("isRegister")!)
        : "";

      console.log("isRegister", isRegister);
      console.log(isRegister == 1);
      console.log(typeof isRegister);

      if (isRegister == "1") {
        presentToast("top", "User authenticated successfully.");
        history.push("/signin", { direction: "none" });
      } else {
        presentToast("top", "OTP verified successfully.");
        history.push("/resetPassword", { direction: "none" });
      }
    }
  }, [isOtpVerifiedSuccess]);

  useEffect(() => {
    if (isOtpVerifiedError) {
      presentToast("top", "Some issue occurred ,Please try again later.");
    }
  }, [isOtpVerifiedError]);

  const verify = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormSubmitted(true);
    let registeredMobile = JSON.parse(localStorage.getItem("registeredMobile")!)
      ? JSON.parse(localStorage.getItem("registeredMobile")!)
      : "";
    data["mobileNo"] = registeredMobile;
    if (!data["otp"]) {
      setOtpError(true);
    } else {
      verifyOTPAction(data);
    }
  };

  const onChange = (valueEvent: any, nameEvent: any, val = null) => {
    const value = val || valueEvent;
    const name = nameEvent;

    setData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleChange = (e: any) => {
    onChange(e.target.value, e.target.name);
  };

  const resendOtp = () => {
    console.log("resendOtp");
  };

  return (
    <IonPage id="login-page">
      <IonContent className="sticky-container">
        <div className="login-logo">
          <img src="assets/img/logo.png" alt="Ionic logo" />
        </div>

        <form className="small-form" noValidate onSubmit={verify}>
          <h3>Verify OTP</h3>
          <IonList>
            <IonItem>
              <IonLabel position="floating">OTP</IonLabel>
              <IonInput
                name="otp"
                type="text"
                value={data["otp"]}
                onIonChange={(e) => {
                  handleChange(e);
                  setOtpError(false);
                }}
                max={4}
                min={4}
                required
              ></IonInput>
            </IonItem>
            {formSubmitted && otpError && (
              <IonText color="danger">
                <p className="ion-padding-start">OTP is required</p>
              </IonText>
            )}
          </IonList>

          <IonRow>
            <IonCol>
              <IonButton type="submit" expand="block">
                Verify OTP
              </IonButton>
            </IonCol>
          </IonRow>

          {/* <IonRow>
            <IonCol>
              <IonButton
                type="button"
                expand="block"
                disabled={disableResend}
                onClick={resendOtp}
              >
                Resend OTP
              </IonButton>
            </IonCol>
          </IonRow> */}

          <IonRow className="adjust-row">
            <IonText color="primary">
              <a href="/signin">Go Back</a>
            </IonText>
          </IonRow>
        </form>
        <CopyRight></CopyRight>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isOtpVerifiedSuccess: state.auth.isOtpVerifiedSuccess,
  isOtpVerifiedError: state.auth.isOtpVerifiedError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    verifyOTPAction: (data: {}) =>
      dispatch({
        type: ACTION_TYPES.OTP_VERIFIED_REQUEST,
        payload: data,
      }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOTP);
