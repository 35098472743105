import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonGrid,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonThumbnail,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonImg,
  IonModal,
  useIonAlert,
  IonIcon,
} from "@ionic/react";
import { ACTION_TYPES } from "../../redux/user/orders/actions";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";

import "./Orders.css";
import AppRater from "../../components/Rater/AppRater";
import { isDesktop, isMobile } from "react-device-detect";
import { leafOutline } from "ionicons/icons";
import { RouteComponentProps, withRouter } from "react-router";
import OrderReviews from "./OrderReviews";
import moment from "moment";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface OrderHistoryDataProps extends OwnProps, DispatchProps {
  orderMenuResponse: [
    {
      menuId: "";
      orderMenuId: Number;
      quantity: Number;
      menuDetails: {
        menuName: "";
        availableQty: "";
        subMenuDetails: [{ subCategoryName: "" }];
        image_link: "";
      };
      subMenuDetails: {
        subCategoryName: "";
        smId: Number;
        subMenuId: "";
        image_link: "";
      };
    }
  ];
  counterAdminId: 0;
  orderId: 0;
  note: "";
  review: null;
  status: null;
  createdOn: "";
}

interface OrderHistoryProps extends OwnProps, DispatchProps {
  getOrderHistoryAction: Function;
  setMenuAction: Function;
  orderHistoryData: Array<OrderHistoryDataProps>;
  isOrderHistorySuccess: boolean;
  isOrderHistoryError: boolean;
}
const OrderHistory: React.FC<OrderHistoryProps> = ({
  history,
  getOrderHistoryAction,
  setMenuAction,
  orderHistoryData,
  isOrderHistorySuccess,
  isOrderHistoryError,
}) => {
  const [presentAlert] = useIonAlert();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedReviews, setSelectedReviews] = useState();
  const [data, setData] = useState({
    startDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
  });

  const setSelectedOrderForReviews = (selectedOrderForReviews: any) => {
    setSelectedReviews(selectedOrderForReviews);
    localStorage.setItem(
      "selectedOrderForReviews",
      JSON.stringify(selectedOrderForReviews)
    );
    history.push("/orderReviews");

    // setIsOpen(true);
  };

  const navigateToReviews = () => {
    history.push("/orderReviews");
  };

  useIonViewWillEnter(() => {
    setMenuAction(true);
    getOrderHistoryAction(data);
  });

  const navigateToOrderSummary = (selectedOrderSummary: any) => {
    console.log(selectedOrderSummary);
    localStorage.setItem(
      "selectedOrderSummary",
      JSON.stringify(selectedOrderSummary)
    );

    history.push("/orderSummary");
  };

  const onChange = (valueEvent: any, nameEvent: any, val = null) => {
    let value = val || valueEvent;
    let name = nameEvent;
    if (name == "toDate") {
      value = moment(value).format("YYYY-MM-DD");
    }
    setData((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleDateChange = (e: any) => {
    console.log(e);
    console.log(e.target.value, e.target.name);
    onChange(e.target.value, e.target.name);
  };

  const handleOrderHistory = () => {
    console.log(data);
    getOrderHistoryAction({
      startDate: data.startDate,
      toDate: data.toDate,
    });
    // getReportsAction({
    //   startDate: data.startDate,
    //   toDate: data.toDate,
    // });
  };

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {isDesktop && (
          <>
            <div className="listing-block">
              <IonLabel className="pagename">
                <h2>Order History</h2>
              </IonLabel>

              <div className="filters-row">
                {/* <IonGrid>
                  <IonRow>
                    <IonCol>
                      <IonList>
                        <IonItem>
                          <IonSelect
                            interface="popover"
                            placeholder="Date range"
                          >
                            <IonSelectOption value="Last 7 days">
                              Last 7 days
                            </IonSelectOption>
                            <IonSelectOption value="Last 15 days">
                              Last 15 days
                            </IonSelectOption>
                            <IonSelectOption value="Last 30 days">
                              Last 30 days
                            </IonSelectOption>
                          </IonSelect>
                        </IonItem>

                        <IonItem>
                          <IonSelect
                            interface="popover"
                            placeholder="Meal type"
                          >
                            <IonSelectOption value="Breakfast">
                              Breakfast
                            </IonSelectOption>
                            <IonSelectOption value="Lunch">
                              Lunch
                            </IonSelectOption>
                            <IonSelectOption value="Snacks">
                              Snacks
                            </IonSelectOption>
                            <IonSelectOption value="Juices">
                              Juices
                            </IonSelectOption>
                            <IonSelectOption value="Dinner">
                              Dinner
                            </IonSelectOption>
                          </IonSelect>
                        </IonItem>
                      </IonList>
                    </IonCol>

                    <IonCol>
                      <IonList>
                        <IonSearchbar></IonSearchbar>
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid> */}
                <IonGrid>
                  <IonRow>
                    <IonCol size="4" size-md="4">
                      <label style={{ margin: ".4rem 0" }} htmlFor="start">
                        Start date:
                      </label>

                      <input
                        type="date"
                        id="from"
                        name="startDate"
                        onChange={(e) => handleDateChange(e)}
                        // min="2018-01-01" max="2018-12-31"
                      />
                    </IonCol>

                    <IonCol size="4" size-md="4">
                      <label htmlFor="start" style={{ margin: ".4rem 0" }}>
                        End date:
                      </label>
                      <input
                        type="date"
                        id="to"
                        name="toDate"
                        onChange={(e) => handleDateChange(e)}
                      />
                    </IonCol>

                    <IonCol size="4" size-md="4">
                      <IonButton onClick={() => handleOrderHistory()}>
                        Get Order History
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>

              <IonList>
                {orderHistoryData.length > 0 &&
                  orderHistoryData.map((history, index) => {
                    return (
                      <>
                        <IonCard className="display-card">
                          <IonCardContent>
                            <div key={index} className="order-history">
                              <IonLabel className="meal-name">
                                <p>Order ID : VP{history.orderId} </p>
                                <p>
                                  <strong>Created on :</strong>
                                  {moment(history.createdOn).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                {history.review == null &&
                                  history.status == 5 && (
                                    <IonButton
                                      onClick={() =>
                                        setSelectedOrderForReviews(history)
                                      }
                                    >
                                      <IonIcon src={leafOutline} />
                                      Add Reviews
                                    </IonButton>
                                  )}
                              </IonLabel>
                              {history.status == 3 && (
                                <strong>THIS ORDER IS REJECTED.</strong>
                              )}

                              {history.orderMenuResponse.length > 0 &&
                                history.orderMenuResponse.map(
                                  (orderMenuResp, subIndex) => {
                                    return (
                                      <div key={subIndex}>
                                        <IonCard className="display-card">
                                          <IonCardContent>
                                            <IonItem lines="none">
                                              <IonGrid>
                                                {/* Here in orderHist we have orderMenuResponse which can be either be a meal (pack) or a individual submenu */}

                                                {/* Here is menuDetails */}
                                                {orderMenuResp.menuDetails !=
                                                  null && (
                                                  <>
                                                    <IonRow>
                                                      <IonCol
                                                        size="4"
                                                        size-md="4"
                                                        className="product-image-block"
                                                      >
                                                        <img
                                                          alt=""
                                                          width={300}
                                                          height={180}
                                                          src={
                                                            orderMenuResp
                                                              .menuDetails
                                                              .image_link
                                                              ? orderMenuResp
                                                                  .menuDetails
                                                                  .image_link
                                                              : "assets/img/menu/default.png"
                                                          }
                                                        />
                                                      </IonCol>
                                                      <IonCol
                                                        size="5"
                                                        size-md="5"
                                                        className="product-detail-block"
                                                      >
                                                        <IonLabel>
                                                          <h3>
                                                            {
                                                              orderMenuResp
                                                                .menuDetails
                                                                ?.menuName
                                                            }
                                                          </h3>
                                                          <h4>
                                                            Ingredients of meal
                                                          </h4>
                                                          <div>
                                                            <ul className="ingredients-list">
                                                              {orderMenuResp.menuDetails?.subMenuDetails.map(
                                                                (
                                                                  item,
                                                                  subMenuIndex
                                                                ) => {
                                                                  return (
                                                                    <div
                                                                      key={
                                                                        subMenuIndex
                                                                      }
                                                                    >
                                                                      <li>
                                                                        {
                                                                          item.subCategoryName
                                                                        }
                                                                      </li>
                                                                    </div>
                                                                  );
                                                                }
                                                              )}
                                                            </ul>
                                                          </div>
                                                        </IonLabel>
                                                      </IonCol>
                                                    </IonRow>
                                                  </>
                                                )}

                                                {/* Here is subMenuDetails */}

                                                {orderMenuResp.subMenuDetails !=
                                                  null && (
                                                  <>
                                                    <IonRow>
                                                      <IonCol
                                                        size="4"
                                                        size-md="4"
                                                        className="product-image-block"
                                                      >
                                                        <IonLabel className="meal-name">
                                                          <h4>
                                                            <b>
                                                              {
                                                                orderMenuResp
                                                                  .subMenuDetails
                                                                  ?.subCategoryName
                                                              }
                                                            </b>
                                                          </h4>
                                                        </IonLabel>
                                                        <img
                                                          alt=""
                                                          width={300}
                                                          height={180}
                                                          src={
                                                            orderMenuResp
                                                              .subMenuDetails
                                                              ?.image_link
                                                              ? orderMenuResp
                                                                  .subMenuDetails
                                                                  ?.image_link
                                                              : "assets/img/menu/default.png"
                                                          }
                                                        />
                                                      </IonCol>
                                                      <IonCol
                                                        size="5"
                                                        size-md="5"
                                                        className="product-detail-block"
                                                      >
                                                        <IonLabel>
                                                          <h3>
                                                            Ingredients of meal
                                                          </h3>
                                                          <div>
                                                            <ul className="ingredients-list">
                                                              <li>
                                                                {
                                                                  orderMenuResp
                                                                    .subMenuDetails
                                                                    ?.subCategoryName
                                                                }
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </IonLabel>
                                                      </IonCol>
                                                    </IonRow>
                                                  </>
                                                )}
                                              </IonGrid>
                                            </IonItem>
                                          </IonCardContent>
                                        </IonCard>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </IonCardContent>
                        </IonCard>
                      </>
                    );
                  })}
              </IonList>
            </div>
          </>
        )}

        {isMobile && (
          <>
            <div className="listing-block">
              <IonLabel className="pagename">
                <h2>Order History</h2>
              </IonLabel>

              <div className="filters-row filter-row-mob">
                <IonGrid>
                  {/* <IonRow>
                    <IonCol size="12" size-md="12">
                      <IonList>
                        <IonItem>
                          <IonSelect
                            interface="popover"
                            placeholder="Date range"
                          >
                            <IonSelectOption value="Last 7 days">
                              Last 7 days
                            </IonSelectOption>
                            <IonSelectOption value="Last 15 days">
                              Last 15 days
                            </IonSelectOption>
                            <IonSelectOption value="Last 30 days">
                              Last 30 days
                            </IonSelectOption>
                          </IonSelect>
                        </IonItem>

                        <IonItem>
                          <IonSelect
                            interface="popover"
                            placeholder="Meal type"
                          >
                            <IonSelectOption value="Breakfast">
                              Breakfast
                            </IonSelectOption>
                            <IonSelectOption value="Lunch">
                              Lunch
                            </IonSelectOption>
                            <IonSelectOption value="Snacks">
                              Snacks
                            </IonSelectOption>
                            <IonSelectOption value="Juices">
                              Juices
                            </IonSelectOption>
                            <IonSelectOption value="Dinner">
                              Dinner
                            </IonSelectOption>
                          </IonSelect>
                        </IonItem>
                      </IonList>
                    </IonCol>
                  </IonRow> */}
                  <IonRow>
                    <IonCol size="6" size-md="6">
                      <label style={{ margin: ".4rem 0" }} htmlFor="start">
                        Start date:
                      </label>

                      <input
                        type="date"
                        id="from"
                        name="startDate"
                        onChange={(e) => handleDateChange(e)}
                        // min="2018-01-01" max="2018-12-31"
                      />
                    </IonCol>

                    <IonCol size="6" size-md="6">
                      <label htmlFor="start">End date:</label>
                      <input
                        type="date"
                        id="to"
                        name="toDate"
                        onChange={(e) => handleDateChange(e)}
                      />
                    </IonCol>

                    <IonCol size="12" size-md="12">
                      <IonButton onClick={() => handleOrderHistory()}>
                        Get Order History
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>

              <IonList>
                <IonRow>
                  {orderHistoryData.length > 0 &&
                    orderHistoryData.map((history, index) => {
                      return (
                        <>
                          {history.orderMenuResponse.length > 0 &&
                            history.orderMenuResponse.map(
                              (orderMenuResp, subIndex) => {
                                return (
                                  <>
                                    {orderMenuResp.menuDetails != null && (
                                      <>
                                        <IonCol
                                          key={subIndex}
                                          size="6"
                                          size-md="6"
                                        >
                                          <IonCard className="display-card">
                                            <IonCardContent>
                                              <div className="product-listing-mob">
                                                <div>
                                                  <img
                                                    alt=""
                                                    src={
                                                      orderMenuResp.menuDetails
                                                        .image_link
                                                        ? orderMenuResp
                                                            .menuDetails
                                                            .image_link
                                                        : "assets/img/menu/default.png"
                                                    }
                                                  />
                                                </div>

                                                <div>
                                                  <IonLabel>
                                                    <h2>
                                                      {
                                                        orderMenuResp
                                                          .menuDetails.menuName
                                                      }
                                                    </h2>
                                                  </IonLabel>
                                                </div>

                                                <div>
                                                  <p>
                                                    <strong>Order ID </strong>:
                                                    VP{history.orderId}
                                                  </p>

                                                  {history.review == null &&
                                                    history.status == 5 && (
                                                      <IonButton
                                                        onClick={() => {
                                                          setSelectedOrderForReviews(
                                                            history
                                                          );
                                                        }}
                                                      >
                                                        <IonIcon
                                                          src={leafOutline}
                                                        />
                                                        Add Reviews
                                                      </IonButton>
                                                    )}

                                                  {history.status == 3 && (
                                                    <strong>
                                                      THIS ORDER IS REJECTED.
                                                    </strong>
                                                  )}
                                                  {history.status == 9 && (
                                                    <strong>
                                                      THIS ORDER IS CANCELLED.
                                                    </strong>
                                                  )}

                                                  {/* {modalData()} */}
                                                  <p
                                                    onClick={() =>
                                                      navigateToOrderSummary(
                                                        history
                                                      )
                                                    }
                                                  >
                                                    <small>
                                                      <a>view order details</a>
                                                    </small>
                                                  </p>
                                                </div>
                                              </div>
                                            </IonCardContent>
                                          </IonCard>
                                        </IonCol>
                                      </>
                                    )}

                                    {/* here is subMenuDetails */}
                                    {orderMenuResp.subMenuDetails != null && (
                                      <>
                                        <IonCol
                                          key={subIndex}
                                          size="6"
                                          size-md="6"
                                        >
                                          <IonCard className="display-card">
                                            <IonCardContent>
                                              <div className="product-listing-mob">
                                                <div>
                                                  <img
                                                    alt=""
                                                    src={
                                                      orderMenuResp
                                                        .subMenuDetails
                                                        ?.image_link
                                                        ? orderMenuResp
                                                            .subMenuDetails
                                                            ?.image_link
                                                        : "assets/img/menu/default.png"
                                                    }
                                                  />
                                                </div>

                                                <div>
                                                  <IonLabel>
                                                    <h2>
                                                      {
                                                        orderMenuResp
                                                          .subMenuDetails
                                                          ?.subCategoryName
                                                      }
                                                    </h2>
                                                  </IonLabel>
                                                </div>

                                                <div>
                                                  <p>
                                                    <strong>Order ID </strong>:
                                                    VP{history.orderId}
                                                  </p>

                                                  {history.review == null &&
                                                    history.status == 5 && (
                                                      <IonButton
                                                        onClick={() => {
                                                          setSelectedOrderForReviews(
                                                            history
                                                          );
                                                        }}
                                                      >
                                                        <IonIcon
                                                          src={leafOutline}
                                                        />
                                                        Add Reviews
                                                      </IonButton>
                                                    )}

                                                  {history.status == 3 && (
                                                    <strong>
                                                      THIS ORDER IS REJECTED.
                                                    </strong>
                                                  )}

                                                  {history.status == 9 && (
                                                    <strong>
                                                      THIS ORDER IS CANCELLED.
                                                    </strong>
                                                  )}

                                                  {/* {modalData()} */}
                                                  <p
                                                    onClick={() =>
                                                      navigateToOrderSummary(
                                                        history
                                                      )
                                                    }
                                                  >
                                                    <small>
                                                      <a>view order details</a>
                                                    </small>
                                                  </p>
                                                </div>
                                              </div>
                                            </IonCardContent>
                                          </IonCard>
                                        </IonCol>
                                      </>
                                    )}
                                  </>
                                );
                              }
                            )}
                        </>
                      );
                    })}
                </IonRow>
              </IonList>
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  orderHistoryData: state.orders.orderHistoryData,
  isOrderHistorySuccess: state.orders.isOrderHistorySuccess,
  isOrderHistoryError: state.orders.isOrderHistoryError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    getOrderHistoryAction: (filters: any) =>
      dispatch({
        type: ACTION_TYPES.GET_ORDER_HISTORY_REQUEST,
        payload: filters,
      }),
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderHistory));
