import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { OrdersAPI } from './OrdersAPI';
import {
    getOrdersSuccess,
    getOrdersError,
    getOrderHistorySuccess,
    getOrderHistoryError,
    getMenuSuccess,
    getMenuError,
    ACTION_TYPES,
    setOrdersError,
    setOrdersSuccess,

    addtoCartSuccess,
    addtoCartError,

    updateCartSuccess,
    updateCartError,

    deleteCartSuccess,
    deleteCartError,

    setCartCount,


    getCartSuccess,
    getCartError,

    addReviewsSuccess,
    addReviewsError,

    updateSubMenuSuccess,
    updateSubMenuError,

    updateMenuSuccess,
    updateMenuError,
    payOrderOnlineError,
    payOrderOnlineSuccess,

    rePayCashRequestSuccess,
    rePayCashRequestError,

    rePayOnlinePaytmRequestSuccess,
    rePayOnlinePaytmRequestError,


    orderCancelRequestSuccess,
    orderCancelRequestError
  } from './actions';

const ordersApi = new OrdersAPI;


function* getOrdersFromApi(action) {
    const data = action.payload;
    try {
        // call the api
        const response = yield call(ordersApi.getOrders, {response: action.payload})
        

        if(response) {
            // call the success action with data
            yield put(getOrdersSuccess(response));
        } else {
            yield put(getOrdersError(response));
        }

    } catch (e) {
        
        // call the error action with data
        yield put(getOrdersError(e));
    }
}


function* getOrdersHistoryFromApi(action) {
    const data = action.payload;
    try {
        // call the api
        const response = yield call(ordersApi.getOrderHistory, action.payload)
        

        if(response.status == 100) {
            // call the success action with data
            yield put(getOrderHistorySuccess(response));
        } else {
            yield put(getOrderHistoryError(response));
        }
    } catch (e) {
        
        // call the error action with data
        yield put(getOrderHistoryError(e));
    }
}


function* getMenuFromApi(action) {
    const data = action.payload;
    try {
        // call the api
        const response = yield call(ordersApi.getMenus, {response: action.payload})
        
        if(response.status == 100) {
            // call the success action with data
            yield put(getMenuSuccess(response));
        } else {
            yield put(getMenuError(response));
        }

    } catch (e) {
        
        // call the error action with data
        yield put(getMenuError(e));
    }
}

function* placeOrderFromApi(action) {
    try {
        // call the api
        const response = yield call(ordersApi.placeOrder, action.payload)
        
        if(response) {
            // call the success action with data
            yield put(setOrdersSuccess(response));
            // localStorage.setItem('cartItemCount', JSON.stringify(0));
        } else {
            yield put(setOrdersError(response));
        }

    } catch (e) {
        
        // call the error action with data
        yield put(setOrdersError(e));
    }
}

function* addToCartFromApi(action) {

    try {
        // call the api
        const response = yield call(ordersApi.addToCart, action.payload)
        
        if(response) {
            // call the success action with data
            yield put(addtoCartSuccess(response));
        } else {
            yield put(addtoCartError(response));
        }
    } catch (e) {
        
        // call the error action with data
        yield put(addtoCartError(e));
    }
}

function* updateCartFromApi(action) {
    try {
        // call the api
        const response = yield call(ordersApi.updateCart, action.payload)
        
        if(response) {
            // call the success action with data
            yield put(updateCartSuccess(response));
        } else {
            yield put(updateCartError(response));
        }
    } catch (e) {
        
        // call the error action with data
        yield put(updateCartError(e));
    }
}


function* deleteCartFromApi(action) {
    try {

        // call the api
        const response = yield call(ordersApi.deleteCart, action.payload);
        

         if(response) {
            // call the success action with data
            yield put(deleteCartSuccess(response));
        } else {
            yield put(deleteCartError(response));
        }

    } catch (e) {
        
        // call the error action with data
        yield put(deleteCartError(e));
    }
}


function* getCartFromApi(action) {

    try {
        // call the api
        const response = yield call(ordersApi.getCart)
        
        if(response.status == 100) {
            // call the success action with data
            yield put(getCartSuccess(response));
        } else {
            yield put(getCartError(response));
        }
    } catch (e) {
        
        // call the error action with data
        yield put(getCartError(e));
    }
}

function* addReviewsFromApi(action) {

    try {
        // call the api
        const response = yield call(ordersApi.addReviews,action.payload)
        
        if(response.status == 100) {
            // call the success action with data
            yield put(addReviewsSuccess(response));
        } else {
            yield put(addReviewsError(response));
        }
    } catch (e) {
        
        // call the error action with data
        yield put(addReviewsError(e));
    }
}

function* updateSubMenuFromApi(action) {

    try {
        // call the api
        const response = yield call(ordersApi.updateSubMenu,action.payload)
        
        if(response.status == 100) {
            // call the success action with data
            yield put(updateSubMenuSuccess(response));
        } else {
            yield put(updateSubMenuError(response));
        }
    } catch (e) {
        
        // call the error action with data
        yield put(updateSubMenuError(e));
    }
}


function* updateMenuFromApi(action) {

    try {
        // call the api
        const response = yield call(ordersApi.updateMenu,action.payload)
        
        if(response.status == 100) {
            // call the success action with data
            yield put(updateMenuSuccess(response));
        } else {
            yield put(updateMenuError(response));
        }
    } catch (e) {
        
        // call the error action with data
        yield put(updateMenuError(e));
    }
}


function* payOrderOnlineFromApi(action) {
    console.log(action);

    try {
        // call the api
        const response = yield call(ordersApi.payOrderOnlinePaytm,action.payload)
        
        if(response.status == 100) {
            // call the success action with data
            yield put(payOrderOnlineSuccess(response));
        } else {
            yield put(payOrderOnlineError(response));
        }
    } catch (e) {
        
        // call the error action with data
        yield put(payOrderOnlineError(e));
    }
}


function* rePayCashRequestFromApi(action) {
    console.log(action);

    try {
        // call the api
        const response = yield call(ordersApi.rePayCashRequest,action.payload)
        // call the success action with data
        yield put(rePayCashRequestSuccess(response));
    } catch (e) {
        
        // call the error action with data
        yield put(rePayCashRequestError(e));
    }
}


// function* rePayOnlinePaytmRequestFromApi(action) {
//     console.log(action);

//     try {
//         // call the api
//         const response = yield call(ordersApi.rePayOnlinePaytmRequest,action.payload)
//         // call the success action with data
//         yield put(rePayOnlinePaytmRequestSuccess(response));
//     } catch (e) {
        
//         // call the error action with data
//         yield put(rePayOnlinePaytmRequestError(e));
//     }
// }

function* payOrderOnlineSodexoFromApi(action) {

    try {
        // call the api
        const response = yield call(ordersApi.payOrderOnlineSodexo,action.payload)
        
        if(response.status == 101) {
            // call the success action with data
            yield put(payOrderOnlineSuccess(response));
        } else {
            yield put(payOrderOnlineError(response));
        }
    } catch (e) {
        
        // call the error action with data
        yield put(payOrderOnlineError(e));
    }
}


function* orderCancelFromApi(action) {

    try {
        // call the api
        const response = yield call(ordersApi.orderCancel,action.payload)
        
        if(response.status == 100) {
            // call the success action with data
            yield put(orderCancelRequestSuccess(response));
        } else {
            yield put(orderCancelRequestError(response));
        }
    } catch (e) {
        
        // call the error action with data
        yield put(orderCancelRequestError(e));
    }
}


export default function* watchUserOrders() {
  yield takeEvery(ACTION_TYPES.GET_ORDERS_REQUEST, getOrdersFromApi)
  yield takeEvery(ACTION_TYPES.GET_ORDER_HISTORY_REQUEST, getOrdersHistoryFromApi)
  yield takeEvery(ACTION_TYPES.GET_MENU_REQUEST, getMenuFromApi),
  yield takeEvery(ACTION_TYPES.SET_ORDER_REQUEST, placeOrderFromApi),
  yield takeEvery(ACTION_TYPES.ADD_TO_CART_REQUEST, addToCartFromApi),
  yield takeEvery(ACTION_TYPES.UPDATE_CART_REQUEST, updateCartFromApi),
  yield takeEvery(ACTION_TYPES.DELETE_CART_REQUEST, deleteCartFromApi),
  yield takeEvery(ACTION_TYPES.GET_CART_REQUEST, getCartFromApi),
  yield takeEvery(ACTION_TYPES.ADD_REVIEWS_REQUEST, addReviewsFromApi),
  yield takeEvery(ACTION_TYPES.UPDATE_SUB_MENU_REQUEST, updateSubMenuFromApi),
  yield takeEvery(ACTION_TYPES.UPDATE_MENU_REQUEST, updateMenuFromApi),
  yield takeEvery(ACTION_TYPES.PAY_ORDER_ONLINE_REQUEST, payOrderOnlineFromApi),
  yield takeEvery(ACTION_TYPES.REPAY_CASH_REQUEST, rePayCashRequestFromApi),  
//   yield takeEvery(ACTION_TYPES.REPAY_ONLINE_PAYTM_REQUEST, rePayOnlinePaytmRequestFromApi),
  yield takeEvery(ACTION_TYPES.PAY_ORDER_ONLINE_SODEXO_REQUEST, payOrderOnlineSodexoFromApi),
  yield takeEvery(ACTION_TYPES.ORDER_CANCEL_REQUEST, orderCancelFromApi)
}
