import React, { useEffect, useState } from "react";
import {
  IonModal,
  IonHeader,
  IonToolbar,
  useIonViewWillEnter,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonBadge,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { COUNTERHEAD_ACTION_TYPES } from "../../redux/user/counterhead/actions";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import {
  trashOutline,
  checkmarkCircleOutline,
  calendar,
  informationCircle,
  map,
  eyeOutline,
} from "ionicons/icons";
import moment from "moment";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface OrderRequestReceivedProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  getIncomingOrderAction: Function;
  isIncomingOrderSuccess: boolean;
  isIncomingOrderError: boolean;
  incomingorderData: any;
  acceptOrderAction: Function;
  rejectOrderAction: Function;
  isOrderAcceptedSuccess: boolean;
  isOrderAcceptedError: boolean;
  isOrderRejectedSuccess: boolean;
  isOrderRejectedError: boolean;
}
const OrderRequestReceived: React.FC<OrderRequestReceivedProps> = ({
  setMenuAction,
  history,
  getIncomingOrderAction,
  isIncomingOrderSuccess,
  isIncomingOrderError,
  incomingorderData,
  acceptOrderAction,
  rejectOrderAction,
  isOrderAcceptedSuccess,
  isOrderAcceptedError,
  isOrderRejectedSuccess,
  isOrderRejectedError,
}) => {
  const [presentAlert] = useIonAlert();
  const [messageToast] = useIonToast();
  const [orderListData, setorderListData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenu, setselectedMenu] = useState([
    {
      menuDetails: { image_link: "", price: 0, menuName: "" },
      quantity: 0,
      subMenuDetails: { image_link: "", price: 0, subCategoryName: "" },
    },
  ]);
  const [productTotal, setproductTotal] = useState(0);

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };

  useIonViewWillEnter(() => {
    setMenuAction(true);
    getIncomingOrderAction(1);
  });

  useEffect(() => {
    if (isIncomingOrderSuccess) {
      setorderListData(incomingorderData.responses);
    }
  }, [isIncomingOrderSuccess]);

  useEffect(() => {
    if (isOrderAcceptedSuccess) {
      presentToast("top", "Order Accepted successfully.");
      getIncomingOrderAction(1);
    }
  }, [isOrderAcceptedSuccess]);

  useEffect(() => {
    if (isOrderAcceptedError) {
      presentToast("top", "Order cannot be accepted.");
    }
  }, [isOrderAcceptedError]);

  useEffect(() => {
    if (isOrderRejectedError) {
      presentToast("top", "Order cannot be rejected.");
    }
  }, [isOrderRejectedError]);

  useEffect(() => {
    if (isOrderRejectedSuccess) {
      presentToast("top", "Order Rejected successfully.");
      getIncomingOrderAction(1);

      // history.push('/counter/orders-inprogress');
    }
  }, [isOrderRejectedSuccess]);

  const handleRejected = (selectedOrder: any) => {
    presentAlert({
      header: "Are you sure?",
      cssClass: "custom-alert",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "OK",
          role: "confirm",
          handler: () => {
            rejectOrderAction(selectedOrder.orderId);
          },
        },
      ],
    });
  };

  const handleAccept = (selectedOrder: any) => {
    presentAlert({
      header: "Estimate time",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "OK",
          role: "confirm",
          handler: (data) => {
            let acceptData = {
              orderId: selectedOrder.orderId,
              expectedTime: data,
            };
            acceptOrderAction(acceptData);
          },
        },
      ],

      inputs: [
        {
          label: "5 minutes",
          type: "radio",
          value: "5",
        },
        {
          label: "10 minutes",
          type: "radio",
          value: "10",
        },
        {
          label: "15 minutes",
          type: "radio",
          value: "15",
        },
      ],
    });
  };

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop &&
                    <> */}

        <div className="listing-block">
          <IonLabel className="pagename">
            <h2>Orders Received</h2>
          </IonLabel>

          <IonCard className="table-layout">
            <IonCardContent>
              {/* <IonRow>
                <IonCol size="6" size-md="6">
                  Filter:
                  <select className="input-field">
                    <option>select</option>
                    <option>Breakfast</option>
                    <option>Lunch</option>
                    <option>Snacks</option>
                  </select>
                </IonCol>

                <IonCol size="6" size-md="6">
                  <input
                    type="text"
                    placeholder="Search by name, id, context"
                    className="input-field input-field-full float-right"
                  />
                </IonCol>
              </IonRow> */}

              <IonRow>
                <IonCol size="12" size-md="12">
                  <div className="table-wrapper">
                    <table id="order-received">
                      <tbody>
                        <tr>
                          <th>Order ID</th>
                          <th>Menu Details</th>
                          {/* <th>Menu</th> */}
                          <th>User ID</th>
                          <th>Time</th>
                          <th>Payment Mode</th>
                          <th></th>
                        </tr>

                        {orderListData.length > 0 &&
                          orderListData.map((order: any, index: Number) => {
                            return (
                              <>
                                <tr>
                                  <td>{order.orderId}</td>
                                  <td>
                                    <a
                                      onClick={() => {
                                        setIsOpen(true);
                                        setselectedMenu(
                                          order.orderMenuResponse
                                        );
                                        let productTotalCal = 0;
                                        order.orderMenuResponse?.forEach(
                                          (elm: any) => {
                                            productTotalCal +=
                                              (elm.menuDetails
                                                ? elm.menuDetails.price
                                                : elm.subMenuDetails.price) *
                                              elm.quantity;
                                          }
                                        );

                                        setproductTotal(productTotalCal);
                                      }}
                                    >
                                      View
                                    </a>
                                  </td>
                                  <td>{order.createdBy}</td>
                                  <td>
                                    {moment(order.createdOn).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>
                                  <td>
                                    {order.paymentMode == 1
                                      ? "PAID AT COUNTER"
                                      : order.paymentMode == 2
                                      ? "PAID ONLINE"
                                      : "-"}
                                  </td>
                                  <td>
                                    <IonButton
                                      color="secondary"
                                      onClick={() => handleAccept(order)}
                                    >
                                      Accept
                                    </IonButton>
                                    <IonButton
                                      color="primary"
                                      onClick={() => handleRejected(order)}
                                    >
                                      Reject
                                    </IonButton>
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        </div>

        <IonModal
          isOpen={isOpen}
          backdropDismiss={false}
          className="large-modal-custom"
        >
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              {selectedMenu.length > 0 && (
                <div className="listing-block ion-padding">
                  <IonLabel className="pagename">
                    <h2>Menu Order Details:</h2>
                  </IonLabel>

                  <IonCard className="display-card cart-view">
                    <IonCardContent>
                      <IonItem lines="none">
                        <IonGrid>
                          <IonRow className="cart-header">
                            <IonCol
                              size="1"
                              size-md="1"
                              className="product-image-block"
                            ></IonCol>
                            <IonCol
                              size="5"
                              size-md="5"
                              className="product-detail-block"
                            ></IonCol>
                            <IonCol
                              size="3"
                              size-md="3"
                              className="product-detail-block"
                            >
                              Price
                            </IonCol>
                            <IonCol
                              size="3"
                              size-md="3"
                              className="product-detail-block"
                            >
                              Quantity
                            </IonCol>
                            <IonCol
                              size="2"
                              size-md="2"
                              className="product-detail-block"
                            ></IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonItem>

                      {selectedMenu.map((menu, index) => {
                        return (
                          <>
                            <IonItem lines="none">
                              <IonGrid>
                                <IonRow>
                                  <IonCol
                                    size="1"
                                    size-md="1"
                                    className="product-image-block"
                                  >
                                    <img
                                      alt=""
                                      src={
                                        menu.menuDetails
                                          ? menu.menuDetails.image_link
                                          : menu.subMenuDetails.image_link
                                      }
                                    />
                                  </IonCol>
                                  <IonCol
                                    size="5"
                                    size-md="5"
                                    className="product-detail-block"
                                  >
                                    {menu.menuDetails
                                      ? menu.menuDetails.menuName
                                      : menu.subMenuDetails.subCategoryName}
                                  </IonCol>
                                  <IonCol
                                    size="3"
                                    size-md="3"
                                    className="product-detail-block"
                                  >
                                    INR.{" "}
                                    {menu.menuDetails
                                      ? menu.menuDetails.price
                                      : menu.subMenuDetails.price}
                                  </IonCol>
                                  <IonCol
                                    size="3"
                                    size-md="3"
                                    className="product-detail-block"
                                  >
                                    <div className="input-count">
                                      {menu.quantity}
                                    </div>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonItem>
                          </>
                        );
                      })}

                      <IonItem lines="none">
                        <IonGrid>
                          <IonRow>
                            <IonCol size="1" size-md="1"></IonCol>
                            <IonCol size="5" size-md="5"></IonCol>
                            <IonCol size="3" size-md="3">
                              Total : {productTotal}
                            </IonCol>
                            <IonCol size="3" size-md="3"></IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonItem>
                    </IonCardContent>
                  </IonCard>
                </div>
              )}
            </IonList>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isIncomingOrderSuccess: state.counterheadData.isIncomingOrderSuccess,
  isIncomingOrderError: state.counterheadData.isIncomingOrderError,
  incomingorderData: state.counterheadData.incomingorderData,
  isOrderAcceptedSuccess: state.counterheadData.isOrderAcceptedSuccess,
  isOrderAcceptedError: state.counterheadData.isOrderAcceptedError,
  isOrderRejectedSuccess: state.counterheadData.isOrderRejectedSuccess,
  isOrderRejectedError: state.counterheadData.isOrderRejectedError,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getIncomingOrderAction: (status: Number) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_INCOMING_ORDER_REQUEST,
        status: status,
      }),
    acceptOrderAction: (acceptData: any) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.ACCEPT_ORDER_REQUEST,
        payload: acceptData,
      }),
    rejectOrderAction: (selectedOrderId: any) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.REJECT_ORDER_REQUEST,
        payload: selectedOrderId,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderRequestReceived));
