import React, { useEffect, useState } from "react";
import {
  useIonViewWillEnter,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonPage,
  IonButtons,
  IonMenuButton,
  IonRow,
  IonCol,
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonText,
  IonTextarea,
  IonToast,
  IonItemDivider,
  IonItemGroup,
  IonItemSliding,
  IonCard,
  IonCardContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonBadge,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonAlert,
  useIonToast,
  IonModal,
} from "@ionic/react";
import { DATA_ACTION_TYPES } from "../../redux/sessions/action";
import { connect } from "react-redux";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import AuthHeader from "../../components/AuthHeader";
import { isDesktop, isMobile } from "react-device-detect";
import {
  trashOutline,
  checkmarkCircleOutline,
  calendar,
  informationCircle,
  map,
  eyeOutline,
} from "ionicons/icons";
import { COUNTERHEAD_ACTION_TYPES } from "../../redux/user/counterhead/actions";
import moment from "moment";

interface OwnProps extends RouteComponentProps {}

interface DispatchProps {}

interface OrderRequestCancelledProps extends OwnProps, DispatchProps {
  setMenuAction: Function;
  getIncomingOrderAction: Function;
  isIncomingOrderSuccess: boolean;
  isIncomingOrderError: boolean;
  incomingorderData: any;
  readyOrderAction: Function;
}
const OrderRequestCancelled: React.FC<OrderRequestCancelledProps> = ({
  setMenuAction,
  history,
  getIncomingOrderAction,
  isIncomingOrderSuccess,
  isIncomingOrderError,
  incomingorderData,
  readyOrderAction,
}) => {
  const [presentAlert] = useIonAlert();
  const [messageToast] = useIonToast();
  const [orderCancelledListData, setorderCancelledListData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMenu, setselectedMenu] = useState([
    {
      menuDetails: { image_link: "", price: 0, menuName: "" },
      quantity: 0,
      subMenuDetails: { image_link: "", price: 0, subCategoryName: "" },
    },
  ]);
  const [productTotal, setproductTotal] = useState(0);

  const presentToast = (
    position: "top" | "middle" | "bottom",
    message: any
  ) => {
    messageToast({
      message: message,
      duration: 3000,
      position: position,
    });
  };
  useIonViewWillEnter(() => {
    setMenuAction(true);
    getIncomingOrderAction(9);
  });

  useEffect(() => {
    if (isIncomingOrderSuccess) {
      console.log(isIncomingOrderSuccess);
      console.log(incomingorderData);
      setorderCancelledListData(incomingorderData.responses);
    }
  }, [isIncomingOrderSuccess]);

  return (
    <IonPage>
      <AuthHeader></AuthHeader>
      <IonContent className="ion-padding">
        {/* {isDesktop && */}
        <>
          <div className="listing-block">
            <IonLabel className="pagename">
              <h2>Orders Cancelled</h2>
            </IonLabel>

            <IonCard className="table-layout">
              <IonCardContent>
                {/* <IonRow>
                  <IonCol size="6" size-md="6">
                    Filter:
                    <select className="input-field">
                      <option>select</option>
                      <option>Breakfast</option>
                      <option>Lunch</option>
                      <option>Snacks</option>
                    </select>
                  </IonCol>

                  <IonCol size="6" size-md="6">
                    <input
                      type="text"
                      placeholder="Search by name, id, context"
                      className="input-field input-field-full float-right"
                    />
                  </IonCol>
                </IonRow> */}

                <IonRow>
                  <IonCol size="12" size-md="12">
                    <div className="table-wrapper">
                      <table id="order-rejected">
                        <tbody>
                          <tr>
                            <th>Order ID</th>
                            <th>Menu Details</th>
                            <th>User</th>
                            {/* <th>Reason</th> */}
                            <th>Cancelled Time</th>
                            <th>Payment Mode</th>
                          </tr>

                          {orderCancelledListData.length > 0 &&
                            orderCancelledListData.map(
                              (order: any, index: Number) => {
                                return (
                                  <>
                                    <tr>
                                      <td>{order.orderId}</td>
                                      <td>
                                        <a
                                          onClick={() => {
                                            setIsOpen(true);
                                            setselectedMenu(
                                              order.orderMenuResponse
                                            );
                                            let productTotalCal = 0;
                                            order.orderMenuResponse?.forEach(
                                              (elm: any) => {
                                                productTotalCal +=
                                                  (elm.menuDetails
                                                    ? elm.menuDetails.price
                                                    : elm.subMenuDetails
                                                        .price) * elm.quantity;
                                              }
                                            );

                                            setproductTotal(productTotalCal);
                                          }}
                                        >
                                          View
                                        </a>
                                      </td>

                                      <td>{order.createdBy}</td>
                                      {/* <td>

                                                                            Reason here..
                                                                        </td> */}
                                      <td>
                                        {moment(order.createdOn).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td>
                                        {order.paymentMode == 1
                                          ? "PAID AT COUNTER"
                                          : order.paymentMode == 2
                                          ? "PAID ONLINE"
                                          : "-"}
                                      </td>
                                    </tr>
                                  </>
                                );
                              }
                            )}
                        </tbody>
                      </table>
                    </div>
                  </IonCol>
                </IonRow>
              </IonCardContent>
            </IonCard>
          </div>
          <IonModal
            isOpen={isOpen}
            backdropDismiss={false}
            className="large-modal-custom"
          >
            <IonHeader>
              <IonToolbar>
                <IonButtons slot="end">
                  <IonButton onClick={() => setIsOpen(false)}>Close</IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              <IonList>
                {selectedMenu.length > 0 && (
                  <div className="listing-block">
                    <IonLabel className="pagename">
                      <h2>Menu Order Details:</h2>
                    </IonLabel>

                    <IonCard className="display-card cart-view">
                      <IonCardContent>
                        <IonItem lines="none">
                          <IonGrid>
                            <IonRow className="cart-header">
                              <IonCol
                                size="1"
                                size-md="1"
                                className="product-image-block"
                              ></IonCol>
                              <IonCol
                                size="5"
                                size-md="5"
                                className="product-detail-block"
                              ></IonCol>
                              <IonCol
                                size="3"
                                size-md="3"
                                className="product-detail-block"
                              >
                                Price
                              </IonCol>
                              <IonCol
                                size="3"
                                size-md="3"
                                className="product-detail-block"
                              >
                                Quantity
                              </IonCol>
                              <IonCol
                                size="2"
                                size-md="2"
                                className="product-detail-block"
                              ></IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonItem>

                        {selectedMenu.map((menu, index) => {
                          return (
                            <>
                              <IonItem lines="none">
                                <IonGrid>
                                  <IonRow>
                                    <IonCol
                                      size="1"
                                      size-md="1"
                                      className="product-image-block"
                                    >
                                      <img
                                        alt=""
                                        src={
                                          menu.menuDetails
                                            ? menu.menuDetails.image_link
                                            : menu.subMenuDetails.image_link
                                        }
                                      />
                                    </IonCol>
                                    <IonCol
                                      size="5"
                                      size-md="5"
                                      className="product-detail-block"
                                    >
                                      {menu.menuDetails
                                        ? menu.menuDetails.menuName
                                        : menu.subMenuDetails.subCategoryName}
                                    </IonCol>
                                    <IonCol
                                      size="3"
                                      size-md="3"
                                      className="product-detail-block"
                                    >
                                      INR.{" "}
                                      {menu.menuDetails
                                        ? menu.menuDetails.price
                                        : menu.subMenuDetails.price}
                                    </IonCol>
                                    <IonCol
                                      size="3"
                                      size-md="3"
                                      className="product-detail-block"
                                    >
                                      <div className="input-count">
                                        <IonItem>
                                          {menu.quantity}
                                          {/* <IonInput
                                            name="quantity"
                                            type="number"
                                            min={0}
                                            max={5}
                                            value={menu.quantity}
                                          ></IonInput> */}
                                        </IonItem>
                                      </div>
                                    </IonCol>
                                  </IonRow>
                                </IonGrid>
                              </IonItem>
                            </>
                          );
                        })}
                        <IonItem lines="none">
                          <IonGrid>
                            <IonRow>
                              <IonCol size="1" size-md="1"></IonCol>
                              <IonCol size="5" size-md="5"></IonCol>
                              <IonCol size="3" size-md="3">
                                Total : {productTotal}
                              </IonCol>
                              <IonCol size="3" size-md="3"></IonCol>
                            </IonRow>
                          </IonGrid>
                        </IonItem>
                      </IonCardContent>
                    </IonCard>
                  </div>
                )}
              </IonList>
            </IonContent>
          </IonModal>
        </>
        {/* } */}

        {/* {isMobile &&
                    <>

                        <div className="listing-block">
                            <IonLabel className="pagename">
                                <h2>OrderRequestCancelled</h2>
                            </IonLabel>

                            <IonCard className="display-card cart-view review-view mob-card-view">
                                <IonCardContent>
                                    <IonItem lines='none'>

                                    </IonItem>

                                </IonCardContent>
                            </IonCard>

                            <div className="mob-card-view-action">

                            </div>
                        </div>

                    </>
                } */}
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state: any) => ({
  isIncomingOrderSuccess: state.counterheadData.isIncomingOrderSuccess,
  isIncomingOrderError: state.counterheadData.isIncomingOrderError,
  incomingorderData: state.counterheadData.incomingorderData,
});

function mapDispatchToProps(dispatch: any) {
  return {
    setMenuAction: (menuEnabled: boolean) =>
      dispatch({
        type: DATA_ACTION_TYPES.SET_MENU_ENABLED,
        payload: menuEnabled,
      }),
    getIncomingOrderAction: (status: Number) =>
      dispatch({
        type: COUNTERHEAD_ACTION_TYPES.GET_INCOMING_ORDER_REQUEST,
        status: status,
      }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OrderRequestCancelled));
